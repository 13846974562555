// -----------------------------------------------------------------------------
// This file contains styles that are specific to the cafe page.
// -----------------------------------------------------------------------------

nav {
	.navbar {
		position: relative;
		z-index: 3;
	}
}

main {
	&.allora-cafe {
		position: relative;
		z-index: 2;
		.banner-container-logo,
		.banner-container {
			position: relative;
			&.banner-container-logo {
				z-index: 2;
			}
			&.banner-container {
				z-index: 1;
			}
		}
	}
}

.row.no-margin {
	margin:0 !important;
}

// Top Anchor Links
.cafe-links-container {
	background-color: $cafecream;
	z-index: 3;
	position: relative;
}
.cafe-links-list {
	margin: 10px;
	.nav-item a {
		font-family: 'sofia-pro';
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: .1em;
		text-decoration: none;
		line-height: 14px;
		margin: 10px 12px 12px 12px;
		padding: 4px 0px;
		color: $cafebrick;
		border-bottom: 2px solid $cafecream;
		&:hover {
					color: $cafecherry;
					border-bottom: 2px solid $cafewheat;
				}
	}
}


//Jumbotron Hero Banner
.cafe-logo {
	width: 240px;
	height: 240px;
	-moz-border-radius: 120px;
		-webkit-border-radius: 120px;
		border-radius: 120px;
	display: flex;
	margin: -40px 20px -90px 20px;
	padding: 40px;
	background-color: $cafelightcream;
	@media (max-width: 768px) {
		width: 200px;
		height: 200px;
		padding: 30px;
		margin-top: -20px;
		margin-bottom: -75px;
	}
}
.cafe-hero-overlay {
	background: radial-gradient(rgba(74,65,58,0), rgba(74,65,58,0.4)) !important;
}
.banner-headline {
	color: $white;
}
.banner-subheadline {
	color: $white;
}



// Cafe Info Section
.cafe-info {
}
.cafe-info-copy {
	font-size: 18px;
	line-height: 28px;
	padding: 0px 40px 0px 40px;
}
.cafe-info-list {
	padding: 0px 40px 0px 40px;
	font-size: 18px;
	line-height: 20px;
	p {
		margin-bottom: 20px;
	}
	b {
		color: black;
	}
}
.link-subtle {
	color: $cafedarkwheat;
	text-decoration: none;
}



// Cafe Menu Section

.cafe-menu-container {
	width: auto;
	margin: 10px 80px 80px 80px !important;
	padding: 90px 130px 90px 130px !important;
	background-color: #FFFFFF;
	box-shadow: 0px 13px 21px -10px rgba(51, 45, 40, 0.2);
}
.cafe-menu-title {
	color: $cafedarkwheat;
	font-size: 32px;
	line-height: 10px;
	margin: 0px 0px 10px 0px;
	padding: 0px;
}
.menu-section-links {
	margin-left: -5px;
	margin-bottom: 20px;
	margin-top: 8px;
	.nav-item {
		padding: 0px 5px 0px 5px;
	}
	.active {
		font-family: 'sofia-pro';
		font-weight: 500;
		color: #000;
		padding-top: 8px;
		padding-left: 0px;
	}
	.nav-item a {
		padding-left: 0px !important;
		text-decoration: none;
		font-size: 18px;
		line-height: 10px;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}
	li+li:before {
		content: "/\00a0";
		display: inline-flex;
		position: absolute;
		padding: 0px 10px 0px 10px;
		transform: translateX(-25px);
	}
}
.cafe-menu-section-title
{
	font-family: 'Labor Union';
	font-size: 24px;
	line-height: 24px;
	text-transform: uppercase;
	letter-spacing: .2em;
	color: $cafedarkwheat;
	margin: 0px 0px 40px 0px;
}
.cafe-menu-item-block {
	margin: 20px 0px 20px 0px;
	p {
		margin-bottom: 0px;
	}
}
.cafe-menu-item-image {
	margin: 0px;
	padding: 0px;
	padding-bottom: 20px;
}
.cafe-menu-item-title {
	font-size: 16px;
	line-height: 20px;
	color: $black;
}
.cafe-menu-item-description {
	font-size: 15px;
	line-height: 20px;
}
.cafe-menu-item-info {
	font-size: 13px;
	line-height: 13px;
	color: $cafewheat;
	margin-top: 8px;
}
.cafe-menu-item-price {
	font-size: 15px;
	line-height: 15px;
	font-weight: 500;
	color: $cafedarkwheat;
	margin-top: 8px;
}
.cafe-menu-headline {
	padding: 20px 20px 10px 20px;
}
.cafe-menu-headline-text {
	color: black;
	font-size: 18px;
	line-height: 18px;
	margin: 0px;
	padding: 0px;
	padding-bottom: 6px;
	letter-spacing: .2em;
}
.cafe-menu-subheadline-text {
	line-height: 16px;
	margin: 0px;
	padding: 0px;
}
.cafe-menu-copy-container {
	p {
		line-height: 18px;
	}
}
.cafe-menu-divider {
	hr {
		overflow: visible; /* For IE */
		padding: 0;
		border: none;
		border-top: 1px solid $cafewheat;
		// border-top: medium double $cafewheat;
		text-align: center;
	}
	hr:after {
		content: url('/assets/images/Cafe/branch-hr-wheat.png');
		color: $cafebrick;
		display: inline-block;
		position: relative;
		top: -1.0em;
		font-size: 1.5em;
		padding: 0 0.75em;
		background: white;
}
}
.cafe-menu-divider-top {
	overflow: visible; /* For IE */
	padding: 0;
	border: none;
	border-top: 1px solid $cafewheat;
	// border-top: medium double $cafewheat;
	color: $cafewheat;
	text-align: center;
}
.cafe-menu-divider-top:after {
		content: url('/assets/images/Cafe/branch-hr-wheat.png');
		color: $cafebrick;
		display: inline-block;
		position: relative;
		top: -1.0em;
		font-size: 1.5em;
		padding: 0 0.75em;
		background: white;
}



// Cafe Gallery
.gallery-slider {
	.slick-arrow {
		background: rgba(black, 0.2);
		color: white;
		text-transform: uppercase;
		font-size: 1.0rem !important;
		padding: 1px 10px;
		border-top: 5px solid $cafecherry;
		@include media-breakpoint-up(md){
			width: 100px;
			height: 50px;
			font-size: 1.5rem;
		}
		@include media-breakpoint-up(lg){
			width: 100px;
			height: 50px;
			font-size: 1.8rem;
		}
	}
	.slick-prev {
		left: 0;
		text-align: right;
		&:before {
			content: '';
		}
	}
	.slick-next {
		right: 0;
		text-align: left;
		&:before {
			content: '';
		}
	}
	.slick-slide {
		opacity: 0.5;
		transition: opacity 0.2s ease-in;
		img {
			background: black;
		}
		&.slick-current {
			opacity: 1;
		}
	}
	.slick-dots {
		margin: -2em 0;
		li {
			margin: 0 0.1em;
			width: 30px;
			@include media-breakpoint-up(md){
				width: 50px;
				margin: 0 0.2em;
			}
			@include media-breakpoint-up(lg){
				width: 60px;
				margin: 0 0.3em;
			}
			button {
				border-top: 1px solid lighten($cafedarkwheat, 30%);
				width: 100%;
				&:before {
					content: '';
				}
			}
			&.slick-active {
				button {
					border-top: 2px solid $cafecherry;
				}
			}
		}
	}
}




// Cafe Location Section

.cafe-location-container {
	width: auto;
	margin: 100px 80px 0px 80px !important;
	padding: 0px !important;
	background-color: #FFFFFF;
	box-shadow: 0px 13px 21px -10px rgba(51, 45, 40, 0.2);
}
.cafe-location-info-list-container  {
	width: auto;
	display: block;
	position: absolute;
	margin: 140px 0px 40px 40px !important;
	background-color: #FFFFFF;
	box-shadow: 0px 13px 21px -10px rgba(51, 45, 40, 0.2);
	z-index: 2;
}
.cafe-location-info-list {
	padding: 40px 20px 20px 40px;
	font-size: 18px;
	line-height: 20px;
	p {
		margin-bottom: 20px;
	}
	b {
		color: black;
	}
}
.cafe-location-map-container {
	height: auto;
	background-color: $cafewheat;
}
.cafe-location-form-container {
	background-color: $cafecream;
	padding: 40px;
}
#cafe-map{
	height: 520px;
	@include media-breakpoint-up(md){
		width: 100%;
		float: right;
		padding: 0px 40px 0px 40px;
	}
}
form {
	.form-control {
		background: $white;
		border: none;
	}
}
.btn-primary {
	background: $cafecherry;
	border: none;
	border-radius: 0;
	color: $white;
	border-bottom: 5px solid $cafebrick;
	&:hover {
		color: white;
		background: $cafebrick;
		border-bottom: 5px solid $cafebrick;
	}
}



/* Media Queries - 992px col-lg or less */
@media (max-width: 992px) {
	.cafe-info-copy {
	padding: 40px 30px 40px 30px;
	}
	.cafe-info-list {
		padding: 30px 30px 0px 30px;
	}
	.cafe-menu-container {
		margin: 40px 0px 0px 0px !important;
		padding: 60px !important;
	}
	.cafe-location-container {
		margin: 0px 40px 0px 40px !important;
	}
	.cafe-location-info-list-container  {
		position: relative;
		margin: 80px 40px 40px 40px !important;
		z-index: 1;
	}
	.cafe-location-map-container {
		margin: 30px 40px 0px 40px;
	}
	.cafe-location-form-container {
		padding: 40px;
	}
	.cafe-menu-headline {
		padding: 20px 10px;
	}
}



/* Media Queries - 768px col-md or less */
@media (max-width: 768px) {
	.menu-section-links {
		.nav-item a {
			font-size: 14px;
		}
	}
	#cafe-links {
		width: 100%;
		flex-direction: column;
		text-align: center;
	}
	#cafe-links li:not(:last-child) {
		border-bottom: 1px solid rgba(0,0,0,0.05);
	}
	#cafe-links li {
			padding-bottom: -2px;
			a {
				padding: 15px;
				margin: 0px 0px -2px 0px;
				border: 1px solid rgba(0,0,0,0);
				&:hover, &:focus {
					border-bottom: 1px solid rgba(0,0,0,0);
				}
			}
	}
	.cafe-location-container {
		margin: 0px !important;
	}
	.cafe-menu-headline {
		padding: 20px 4px;
	}
}




/* Media Queries - 576px col- or less */
@media (max-width: 576px) {
	.cafe-menu-container {
		margin: 40px 0px 0px 0px !important;
		padding: 30px !important;
	}
	.cafe-menu-headline {
		padding: 20px 14px;
	}
}
