// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn-primary {
  background: darken($lightgray, 20%);
  border: none;
  border-radius: 0;
  color: $darkgray;
  border-bottom: 5px solid $darkgray;
  &:hover {
    color: white;
    background: $darkgray;
  }
}