.navbar {
  background-color: white;
  color: $gray;
  @extend .elevation;
  position: fixed;
  width: 100vw;
  z-index: 999;
  padding: 20px 80px;
  @media (max-width: 768px) {
    padding: 30px 20px 10px 20px;
  }
  .navbar-action-items {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    align-items: center;
    .fa-search {
      color: $darkgray;
      margin-right: 1em;
      @media (max-width: 768px) {
        margin-right: 0.1em;
      }
    }
    .navbar-toggler {
      border: none;
      display: inline;
      color: $gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: right;
      .navbar-toggler-icon {
        position: relative;
        width: 3em;
        .navbar-strike {
          position: absolute;
          height: 0;
          width: 2px;
          top: -9px;
          right: 10px;
          background: $gray;
          transition: height 0.1s ease-in-out;
          &.clicked {
            height: 90%;
          }
        }
      }
      .navbar-toggler-text {
        text-transform: uppercase;
      }
    }
  }
}

.sidr {
  background: $lightgray;
  z-index: 998;
  @extend .elevation;
  padding-top: 8em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(md){
    width: 30%;
  }
  &.right {
    right: -100%;
  }
  ul {
    text-align: center;
    li {
      font-size: 2.5rem; 
      margin: 1em 0;
      @include media-breakpoint-up(md){
        font-size: 2rem;
      }
      a {
        color: darken($color: theme-color(primary), $amount: 10%);
        text-decoration: none; 
        font-weight: 100;
        &.active {
          color: black;
          font-weight: 700;
        }
      }
    }
  }
}
