.search {
  .search-form {
    @include media-breakpoint-up(md){
      padding: 30% 20% 0;
    }
    @include media-breakpoint-up(lg){
      padding-left: 25%;
      padding-right: 25%;
    }
    .input-group{
      input{
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid $gray;
        border-radius: 0;
        font-size: 1.2rem;
        @include media-breakpoint-up(md){
          font-size: 1.5rem;
        }
      }
      .input-group-append{
        .btn-link{
          border-bottom: 2px solid $gray;
          border-radius: 0;
        }
      }
    }
  }
}