// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.jumbotron {
  text-align: center;
}

.navbar-toggler-text {
	font-family: "Berthold Regular";
	font-weight: 300;
}

.sidr {
	.list-unstyled {
		.list-unstyled-item {
			font-family: "Berthold Regular";
			font-weight: 300;
		}
	}

}