// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.page-footer {
	font-family: "Berthold Regular";
  color: white;
  background: $gray;
  padding: 1em;
  text-align: center;
  strong {
  	font-family: "Berthold Bold";
  }
}