// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.homepage {
  margin: 4.8em 0 5em;
  .jumbotron {
    height: 78vh;
    position: relative;
    overflow: hidden;
    z-index: 0;
    border-radius: 0;
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      background: url('//unsplash.it/800/400') no-repeat;
      background-size: cover;
      transition: 1s opacity;
    }
  }
  .teaser {
    margin-bottom: 7em;
  }
  .body {
    font-size: 1.2rem;
  }
}
