/**
 * Basic typography style for copy text
 */

body {
  background-color: $cafelightcream !important;
  color: $cafedarkwheat;
  font-family: 'sofia-pro';
  line-height: 2;
  font-weight: 300;

  h1,h2,h3 {
    font-family: 'Labor Union';
    letter-spacing: .2em;
    line-height: 36px;
  }

  h4,h5,h6 {
    font-family: 'sofia-pro';
    font-weight: 500;
  }

  h1,h2 {
    color: $cafedarkwheat;
  }

  .highlight-title {
    text-transform: uppercase;
    font-size: 1rem;
    @include media-breakpoint-up(md){
      &:before {
        content: '_____________';
        margin-right: 1em;
        margin-bottom: 1em;
      }
    } 
  }

  p {
    font-weight: 300; 
  }

  strong { font-weight: 500; }

  a {
    color: $cafecherry;
    font-weight: 500;
    text-decoration: none;
    &:hover, &:focus {
      color: $cafebrick;
    }
  }
}

html {
  font-size: 14px;
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

blockquote {
  @extend .blockquote;
  font-size: 1rem;
  font-size: 300;
}
