// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $lightgray;
  main {
    flex: 1 0 auto;
    margin: 8em 0 5em;
    .card {
      border: none;
    }
    .container-fluid {
      padding: 0;
      margin: 0;
    }
    .page-content {
      position: relative;
      .page-title {
        @include media-breakpoint-up(md) {
          margin-bottom: 1em;
        }
      }
      .title-border { 
        position: absolute;
        left: 0.5em;
        top: -0.5em;
        width: 3em;
        height: auto;
        @include media-breakpoint-up(md) {
          top: -1em;
          left: -1em;
          width: 5em;
        }
      }
    }
  }
}