.overflow {
  .image {
    position: fixed;
    top: 0;
    z-index: 3;
    background: white;
    width: 100%;
    padding: 1em;
    text-align: center;
    img {
      max-width: 15em;
    }
  }
  .landing-page {
    position: relative;
    .content {
      width: 100%;
      position: fixed;
      bottom: 0;
      z-index: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: white;
      padding: 1em 1em 3em;
      background: linear-gradient(rgba(black, 0), rgba(black, 0.5) 20%);
      p {
        text-shadow: 2px 2px 2px black;
      }
      button {
        min-width: 10em;
        color: white;
        font-size: 1.5rem;
        i {
          margin-right: 1em;
          transform: rotate(0deg);
          transition: all 0.1s ease-in-out;
        }

        &:hover {
          i {
            transform: rotate(90deg);
          }
        }
      }
      .render-text {
        text-shadow: none;
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: lighten(theme-color(primary), 10%);
      }
    }
    .slider {
      z-index: -2;
      position: fixed;
      height: 100%;
      width: 100%;
      .slide-img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 100vh;
      }
    }
    .slick-dots {
      @include media-breakpoint-up(md) {
        position: fixed;
        bottom: 60%;
        right: 0;
        list-style: block;
        text-align: right;
        width: 3%;
        li {
          margin-bottom: 2em;
          width: 100%;
          text-align: right;
          button::before {
            color: white;
            font-size: 1.3rem;
            font-family: 'aktiv-grotesk';
          }
          &:first-child {
            button::before {
              content: '1_____';
            }
          }
          &:nth-child(2) {
            button::before {
              content: '2_____';
            }
          }
          &:nth-child(3) {
            button::before {
              content: '3_____';
            }
          }
          list-style: block;
          display: block;
        }
      }
    }
  }
  .landing-page-about {
    margin-top: 100vh;
    position: relative;
    background: white;
    min-height: 100vh;
    padding: 8em 1em 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .container {
      position: relative;
      padding: 0 5em;
      .border-img {
        position: absolute;
        top: 1.5em;
        left: 4em;
        width: 3em;
        height: auto;
      }
      .btn {
        position: absolute;
        top: -10%;
        right: 0;
        font-size: 2rem;
        transform: rotate(0deg);
        transition: transform 0.1s ease-in-out;
        &:hover,
        &:focus {
          transform: rotate(90deg);
        }
      }
      h2 {
        margin: 1em 0 2em;
      }
    }
  }
}
