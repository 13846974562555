.gallery-slider {
  position: relative;
  .gallery-slide {
    height: 74vh;
  }
  .slick-arrow {
    z-index: 2;
    top: auto;
    bottom: 0;
    width: 80px;
    height: 30px;
    background: rgba(black, 0.2);
    color: white;
    font-size: 1rem;
    padding: 1px 3px;
    border-top: 5px solid white;
    @include media-breakpoint-up(md){
      width: 100px;
      height: 50px;
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(lg){
      width: 150px;
      height: 50px;
      font-size: 1.8rem;
    }
  }
  .slick-prev {
    left: 0;
    text-align: right;
    &:before {
      content: '';
    }
  }
  .slick-next {
    right: 0;
    text-align: left;
    &:before {
      content: '';
    }
  }
  .slick-slide {
    opacity: 0.5;
    transition: opacity 0.2s ease-in;
    img {
      background: black;
    }
    &.slick-current {
      opacity: 1;
    }
  }
  .slick-dots {
    margin: -2em 0;
    li {
      margin: 0 0.1em;
      width: 30px;
      @include media-breakpoint-up(md){
        width: 50px;
        margin: 0 0.2em;
      }
      @include media-breakpoint-up(lg){
        width: 60px;
        margin: 0 0.3em;
      }
      button {
        border-top: 1px solid lighten($gray, 30%);
        width: 100%;
        &:before {
          content: '';
        }
      }
      &.slick-active {
        button {
          border-top: 1px solid black;
        }
      }
    }
  }
}

.title-slider{
  position: relative;
  margin: 80px;
  .overlay {
    position: absolute;
    width: 100%;
    height: 78vh;
    z-index: 1;
    background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.4));
  }
  .slider-content {
    z-index: 2;
    padding: 1em 6em;
    position: absolute;
    height: 30%;
    width: 100%;
    bottom: 70px;
    left: 0;
    color: white;
    @include media-breakpoint-up(md){
      bottom: 0;
    }
    .more-information {
      color: white;
    }
    .render-text {
      text-align: right;
      opacity: 0.5;
    }
  }
}

.cafe-slider{
  position: relative;
  margin: 0px 80px 0px 80px;
  height: 60vh;
  .overlay {
    position: absolute;
    width: 100%;
    height: 60vh;
    z-index: 1;
    background: radial-gradient(rgba(0,0,0,0), rgba(0,0,0,.4));
  }
  .slider-content {
    z-index: 2;
    padding: 2em 6em;
    position: absolute;
    min-height: 20%;
    width: 100%;
    bottom: 40px;
    left: 0;
    color: white;
    line-height: 16px;
    h1 {
      line-height: 16px;
    }
    @include media-breakpoint-up(md){
      bottom: 0;
    }
    .more-information {
      color: white;
    }
    .render-text {
      text-align: right;
      opacity: 0.5;
    }
  }
}

.cafe-slider-container {
  .jumbotron {
    height: 60vh;
  }
}

#homeSlider {
  position: relative;
  .slick-dots {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 20px;
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    >li {
      display: block;
      width: 100%;
      margin-bottom: 1em;
      button {
        width: 100%;
        height: 50px;
        font-size: 2rem;
        &:before {
          content: '01 ____';
          font-size: 1.5rem;
          width: 100%;
          height: 100%;
          text-align: right;
          color: white;
          font-family: 'Berthold Regular';
        }
      }
      &:nth-child(2){
        button:before {
          content: '02 ____'
        }
      }
      &:nth-child(3){
        button:before {
          content: '03 ____'
        }
      }
      &:nth-child(4){
        button:before {
          content: '04 ____'
        }
      }
      &:nth-child(5){
        button:before {
          content: '05 ____'
        }
      }
      &:nth-child(6){
        button:before {
          content: '06 ____'
        }
      }
      &:nth-child(7){
        button:before {
          content: '07 ____'
        }
      }
      &:nth-child(8){
        button:before {
          content: '08 ____'
        }
      }
      &:nth-child(9){
        button:before {
          content: '09 ____'
        }
      }
      &:nth-child(10){
        button:before {
          content: '10 ____'
        }
      }
      &:nth-child(11){
        button:before {
          content: '11 ____'
        }
      }
      &:nth-child(12){
        button:before {
          content: '12 ____'
        }
      }
    }
  }
}



/* Media Queries - 992px col-lg or less */
@media (max-width: 992px) {
  .cafe-slider {
    margin: 0px 40px 40px 40px;
  }
}




/* Media Queries - 768px col-md or less */
@media (max-width: 768px) {
  .cafe-slider {
    margin: 0px;
  }
  .slider-content {
    margin: 0px !important;
    padding: 0px 30px 0px 30px !important;
    bottom: 0px !important;
    left: 0px;
    h1 {
      line-height: 16px;
    }
  }
  #homeSlider {
    .slick-dots {
      >li {
        button {
          display: none;
        }
      }
    }
  }
}




/* Media Queries - 576px col- or less */
@media (max-width: 576px) {
}
