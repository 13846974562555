.highlights {
  margin: 3em 0 6em 0;
  position: relative;

  .highlight-description {
    .card {
      border: none;
      background-color: white;
      @extend .elevation;
      @include media-breakpoint-up(md) {
        width: 25em;
        top: 151px;
      }
      @include media-breakpoint-up(lg) {
        width: 50%;
      }
      ul {
        list-style-type: none;
        padding-left: 60px;
      }
      .highlight-title {
        margin-bottom: 40px;
      }
      .card-body {
        padding: 30px;
      }
    }
  }
  .highlight-img {
    max-width: 100%;
    height: auto;
    @include media-breakpoint-up(md) {
      position: absolute;
      right: 0;
      top: 10%;
      width: auto;
      height: 100%;
      z-index: -1;
    }
  }
}

.card.quote {
  padding: 30px 10px;
  .blockquote {
    p {
      padding-bottom: 20px;
      color: black;
    }
  }
  .blockquote-footer {
    text-transform: uppercase;
    font-weight: bold;
  }
}
