.visit {
  margin-top: 12em;
  @include media-breakpoint-up(md){ 
    margin-top: 12em;
   }
}

#map{
  height: 400px;
  @include media-breakpoint-up(md){
    width: 60%;
    float: right;
  }
}

.visit-des-moines {
  position: relative;
  @include media-breakpoint-up(lg){
    padding: 0 10em;
  }
  .card {
    @extend .elevation;
    padding: 50px;
    @include media-breakpoint-up(md){
      position: absolute;
      top: 4em;
      width: 50%;
      z-index: 2;
      left: 50px;
    }
    @include media-breakpoint-up(lg){
      width: 45%;
      left: 50px;
    }
    .card-text {
      padding-left: 20px;
    }
  }
}