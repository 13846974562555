// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
$gray: #646569;
$ltgray: #96989E;
$lightgray: #FAFAFA;
$darkgray: #333333;
$cafebrick: #A92525;
$cafecherry: #E34D39;
$cafecream: #FAECD6;
$cafelightcream: #faf6f0;
$cafewheat: #CAB19F;
$cafedarkwheat: #7d6e63;

$theme-colors: (
  "primary": $gray,
  "secondary": black,
  "tertiary": white
);