// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Berthold Regular';
  src: url('/assets/fonts/berthold_akzidenz_grotesk_be_regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/berthold_akzidenz_grotesk_be_regular-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Berthold Bold';
  src: url('/assets/fonts/berthold_akzidenz_grotesk_bold.woff') format('woff');
}

@font-face {
  font-family: 'Labor Union';
  src: url('/assets/fonts/labor-union-regular.woff') format('woff'),
  	url('/assets/fonts/labor-union-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bitstream Vera Serif Bold';
  src: url('https://mdn.mozillademos.org/files/2468/VeraSeBd.ttf');
}